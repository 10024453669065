import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Heading from "../components/Heading";

const NotFoundPage = ({ data }) => {
  const heading = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Helmet>
        <title> Page not found </title>
      </Helmet>
      <Heading
      title="Página no encontrada"
      subtitle="Código de error: 404"
      image={heading.imagecontact.childImageSharp.gatsbyImageData} />
      <section className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="error-text text-center">
                <h6>
                  Parece que no podemos encontrar la página que está buscando.
                </h6>
                <Link to="/">Volver al inicio</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;

export const data = graphql`
  {
    markdownRemark {
      frontmatter {
        imagecontact {
          childImageSharp {
            gatsbyImageData(formats: WEBP)
          }
        }
        altimage
      }
    }
  }
`;
